import React, { useState, useEffect, useCallback } from "react";
import bgnews from "../../asset/Body2.png";
import news from "../../asset/new.png";
import secondImage from "../../asset/New1.png";
import { useLanguage } from "../../context/LanguageContext";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MarkdownRenderer = ({ content }) => (
	<ReactMarkdown
		remarkPlugins={[remarkGfm]}
		className="text-gray-700 mb-6 text-justify font-helvetica"
		components={{
			h1: ({ node, ...props }) => (
				<h1
					className="lg:text-5xl md:text-4xl sm:text-2xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 1 Content"}
				</h1>
			),
			h2: ({ node, ...props }) => (
				<h2
					className="lg:text-4xl md:text-3xl sm:text-xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 2 Content"}
				</h2>
			),
			h3: ({ node, ...props }) => (
				<h3
					className="lg:text-3xl md:text-2xl sm:text-large text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 3 Content"}
				</h3>
			),
			p: ({ node, ...props }) => (
				<p
					className="lg:text-2xl md:text-xl text-base text-gray-700 mt-2 mb-4 font-helvetica"
					{...props}
				>
					{props.children || "Default paragraph content"}
				</p>
			),
			table: ({ node, ...props }) => (
				<table
					className="w-full border-separate border-spacing-0 my-4 border border-black font-helvetica"
					{...props}
				/>
			),
			th: ({ node, ...props }) => (
				<th
					className="p-2 border border-black text-left font-semibold bg-gray-200 font-helvetica"
					{...props}
				/>
			),
			td: ({ node, ...props }) => (
				<td
					className="p-2 border border-black text-left font-helvetica"
					{...props}
				/>
			),
			img: ({ node, ...props }) => (
				<img
					className="max-w-full h-auto my-4"
					loading="lazy"
					alt={props.alt || "Description of image"}
					{...props}
				/>
			),
			em: ({ node, ...props }) => (
				<em
					className="italic font-helvetica"
					{...props}
				/>
			),
			strong: ({ node, ...props }) => (
				<strong
					className="font-bold font-helvetica"
					{...props}
				/>
			),
			a: ({ node, ...props }) => (
				<a
					className="text-blue-600 hover:underline font-helvetica"
					{...props}
				>
					{props.children || "Link text"}
				</a>
			),
			// Tambahkan komponen untuk underline
			u: ({ node, ...props }) => (
				<u
					className="underline font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk bullet list (ul)
			ul: ({ node, ...props }) => (
				<ul
					className="list-disc ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk penomoran (ol)
			ol: ({ node, ...props }) => (
				<ol
					className="list-decimal ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk list item (li)
			li: ({ node, ...props }) => (
				<li
					className="mb-1 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk blockquote
			blockquote: ({ node, ...props }) => (
				<blockquote
					className="border-l-4 border-gray-400 pl-4 italic text-gray-600 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk code block
			code: ({ node, inline, className, children, ...props }) => {
				return inline ? (
					<code
						className="bg-gray-100 p-1 rounded font-mono text-sm"
						{...props}
					>
						{children}
					</code>
				) : (
					<pre className="bg-gray-800 text-white p-4 rounded my-4 overflow-x-auto">
						<code {...props}>{children}</code>
					</pre>
				);
			},
		}}
	>
		{content}
	</ReactMarkdown>
);

function SustainabilityHome() {
	const [isPopupOpen, setPopupOpen] = useState(false);
	const [currentItem, setCurrentItem] = useState(null);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [newsItems, setNewsItems] = useState([]);
	const { language } = useLanguage();
	const [data, setData] = useState(null);
	const itemsPerPage = 6;

	const API_URL = process.env.REACT_APP_API_URL; // Ensure this is set in .env
	const API_TOKEN = process.env.REACT_APP_API_TOKEN; // Ensure this is set in .env

	const fetchCardSustainability = useCallback(
		async (page = 1) => {
			const query = `
            query SocialPage {
                socialPage(locale: "${language}") {
                    Activity {
                        Activities(
                            pagination: { page: ${page}, pageSize: ${itemsPerPage} }
                            sort: ["Display_Order:desc", "Date:desc"]
                            filters: {
                                Pin_toHome: { eq: true }
                                Display_Order: { not: null }
                                Date: { not: null }
                            }
                        ) {
                            Title
                            Image {
                                formats
                                url
                            }
                            Description
                            Isnew
                            Date
                            Display_Order
                        }
                    }
                }
                environmentPage(locale: "${language}") {
                    Target_Activity_Plan {
                        Activities(
                            pagination: { page: ${page}, pageSize: ${itemsPerPage} }
                            sort: ["Display_Order:desc", "Date:desc"]
                            filters: {
                                Pin_toHome: { eq: true }
                                Display_Order: { not: null }
                                Date: { not: null }
                            }
                        ) {
                            Title
                            Image {
                                formats
                                url
                            }
                            Description
                            Isnew
                            Date
                            Display_Order
                        }
                    }
                }
            }
        `;

			try {
				const response = await fetch(`${API_URL}/graphql`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${API_TOKEN}`,
					},
					body: JSON.stringify({ query }),
				});
				const result = await response.json();

				if (result.data) {
					const socialActivities = result.data.socialPage.Activity.flatMap(
						(activity) => activity.Activities
					);
					const environmentActivities =
						result.data.environmentPage.Target_Activity_Plan.flatMap(
							(plan) => plan.Activities
						);

					// Gabungkan data dan sort berdasarkan Display_Order dan Date
					const combinedActivities = [
						...socialActivities,
						...environmentActivities,
					]
						.filter((item) => item && item.Title && item.Image)
						.sort((a, b) => {
							if (b.Display_Order === a.Display_Order) {
								return new Date(b.Date) - new Date(a.Date);
							}
							return b.Display_Order - a.Display_Order;
						})
						.slice(0, 6);

					setNewsItems(combinedActivities);
				} else {
					console.error(
						"No data received or incorrect data structure:",
						result
					);
				}
			} catch (error) {
				console.error("Error fetching report data:", error.message);
			}
		},
		[API_URL, language, itemsPerPage, API_TOKEN]
	);

	const fetchHome = useCallback(async () => {
		const query = `
            query {
                home(locale: "${language}") {
                    Sustainability_Title
					Sustainability_SubTitle
                }
            }
        `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const fetchedData = result.data.home;
			setData(fetchedData);
		} catch (error) {
			console.error("Error fetching home data:", error.message);
		}
	}, [API_URL, language]);

	useEffect(() => {
		fetchCardSustainability();
		fetchHome();
	}, [fetchCardSustainability, fetchHome]);

	const handlePopupOpen = (item) => {
		setCurrentItem(item);
		setCurrentImageIndex(0);
		setPopupOpen(true);
	};

	const handlePopupClose = () => {
		setPopupOpen(false);
		setCurrentItem(null);
	};

	const handleNextImage = () => {
		if (
			currentItem &&
			currentItem.Images &&
			currentImageIndex < currentItem.Images.length - 1
		) {
			setCurrentImageIndex(currentImageIndex + 1);
		}
	};

	const handlePrevImage = () => {
		if (currentItem && currentItem.Images && currentImageIndex > 0) {
			setCurrentImageIndex(currentImageIndex - 1);
		}
	};

	return (
		<div className="font-dmsans">
			{/* Desktop */}
			<section
				className="hidden lg:block pt-20 pb-20 relative bg-cover bg-center min-h-screen z-20"
				style={{
					backgroundImage: `url(${bgnews})`,
				}}
			>
				<div className="container mx-auto px-10 md:px-20">
					<div className="flex items-center justify-between mb-5">
						<h2 className="text-3xl font-bold text-left text-[#2E3491]">
							{data?.Sustainability_Title}
						</h2>
					</div>

					<div className="flex items-center justify-between mb-5">
						<h3 className="text-2xl font-semibold text-left text-[#2E3491]">
							{data?.Sustainability_SubTitle}
						</h3>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-3 gap-6">
						{newsItems.map((item, index) => (
							<button
								key={index}
								onClick={() => handlePopupOpen(item)}
								className="relative"
							>
								<div
									className="w-full h-[500px] bg-cover bg-center rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105" // Add hover effect here
									style={{
										backgroundImage:
											item.Image?.length > 0
												? `url(${API_URL}${item.Image[0].url})`
												: "url(/path/to/placeholder-image.png)", // Fallback image
										borderRadius: "15px",
									}}
								>
									<div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-between p-4 rounded-lg">
										<div className="absolute bottom-4 left-4">
											<h4 className="text-lg font-semibold text-white text-left">
												{item.Title}
											</h4>
										</div>
										<div className="flex flex-col absolute top-4 right-4 space-y-2">
											<img
												src={news} // Ensure `news` is correctly imported or defined
												alt="Deskripsi Gambar"
												className="w-20 h-15"
											/>
											{item.Isnew && (
												<img
													src={secondImage} // Ensure `secondImage` is correctly imported or defined
													alt="Deskripsi Gambar Kedua"
													className="w-20 h-5"
												/>
											)}
										</div>
									</div>
								</div>
							</button>
						))}
					</div>
				</div>
			</section>

			{/* Tablet */}
			<section
				className="hidden md:block lg:hidden pt-20 pb-20 relative bg-cover bg-center min-h-screen z-20"
				style={{
					backgroundImage: `url(${bgnews})`,
				}}
			>
				<div className="container mx-auto px-10 md:px-20">
					<div className="flex items-center justify-between mb-5">
						<h2 className="text-3xl font-bold text-left text-[#2E3491]">
							{data?.Sustainability_Title}
						</h2>
					</div>

					<div className="flex items-center justify-between mb-5">
						<h3 className="text-2xl font-semibold text-left text-[#2E3491]">
							{data?.Sustainability_SubTitle}
						</h3>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
						{newsItems.map((item, index) => (
							<button
								key={index}
								onClick={() => handlePopupOpen(item)}
								className="relative"
							>
								<div
									className="w-full h-[400px] bg-cover bg-center rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105" // Add hover effect here
									style={{
										backgroundImage:
											item.Image && item.Image.length > 0
												? `url(${API_URL}${item.Image[0].url})`
												: "url(/path/to/placeholder-image.png)", // Fallback image
										borderRadius: "15px",
									}}
								>
									<div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-between p-4 rounded-lg">
										<div className="absolute bottom-4 left-4">
											<h4 className="text-lg font-semibold text-white text-left">
												{item.Title}
											</h4>
										</div>
										<div className="flex flex-col absolute top-4 right-4 space-y-2">
											<img
												src={news} // Ensure `news` is correctly imported or defined
												alt="Deskripsi Gambar"
												className="w-20 h-15"
											/>
											{item.Isnew && (
												<img
													src={secondImage} // Ensure `secondImage` is correctly imported or defined
													alt="Deskripsi Gambar Kedua"
													className="w-20 h-5"
												/>
											)}
										</div>
									</div>
								</div>
							</button>
						))}
					</div>
				</div>
			</section>

			{/* Mobile */}
			<section
				className="block md:hidden pt-20 pb-20 relative bg-cover bg-center min-h-screen z-20"
				style={{
					backgroundImage: `url(${bgnews})`,
				}}
			>
				<div className="container mx-auto px-10 md:px-20">
					<div className="flex items-center justify-between mb-5">
						<h2 className="text-3xl font-bold text-left text-[#2E3491]">
							{data?.Sustainability_Title}
						</h2>
					</div>

					<div className="flex items-center justify-between mb-5">
						<h3 className="text-2xl font-semibold text-left text-[#2E3491]">
							{data?.Sustainability_SubTitle}
						</h3>
					</div>

					<div className="grid grid-cols-1 gap-6">
						{newsItems.map((item, index) => (
							<button
								key={index}
								onClick={() => handlePopupOpen(item)}
								className="relative"
							>
								<div
									className="w-full h-[400px] bg-cover bg-center rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105" // Add hover effect here
									style={{
										backgroundImage:
											item.Image && item.Image.length > 0
												? `url(${API_URL}${item.Image[0].url})`
												: "url(/path/to/placeholder-image.png)", // Fallback image
										borderRadius: "15px",
									}}
								>
									<div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-between p-4 rounded-lg">
										<div className="absolute bottom-4 left-4">
											<h4 className="text-lg font-semibold text-white text-left">
												{item.Title}
											</h4>
										</div>
										<div className="flex flex-col absolute top-4 right-4 space-y-2">
											<img
												src={news} // Ensure `news` is correctly imported or defined
												alt="Deskripsi Gambar"
												className="w-20 h-15"
											/>
											{item.Isnew && (
												<img
													src={secondImage} // Ensure `secondImage` is correctly imported or defined
													alt="Deskripsi Gambar Kedua"
													className="w-20 h-5"
												/>
											)}
										</div>
									</div>
								</div>
							</button>
						))}
					</div>
				</div>
			</section>

			{/* Popup */}
			{isPopupOpen && currentItem && (
				<div
					className="fixed inset-0 top-0 bottom-0 z-40 flex items-center justify-center bg-black bg-opacity-50 p-4"
					onClick={handlePopupClose} // Close when clicking on the backdrop
				>
					<div
						className="bg-white p-4 md:p-6 rounded-lg w-full max-w-[90%] md:max-w-3xl lg:max-w-4xl h-auto max-h-[80vh] overflow-y-auto shadow-lg relative"
						style={{ marginTop: "14rem", marginBottom: "6rem" }} // Add margin for spacing
						onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
					>
						<button
							className="absolute top-0 right-0 bg-red-500 text-white hover:bg-red-600 z-10"
							onClick={handlePopupClose}
							style={{
								width: "30px",
								height: "30px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								className="w-4 h-4"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>

						<div className="relative">
							{currentItem.Image && currentItem.Image.length > 0 ? (
								<img
									src={`${API_URL}${currentItem.Image[0]?.url}`}
									alt={currentItem.Title || "Image"}
									className="w-full h-auto object-contain mb-4"
								/>
							) : (
								<p className="text-center text-gray-500">
									Gambar tidak tersedia
								</p>
							)}

							{currentImageIndex > 0 && (
								<button
									onClick={handlePrevImage}
									className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-transparent text-white text-3xl"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										className="w-8 h-8"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M15 19l-7-7 7-7"
										/>
									</svg>
								</button>
							)}
							{currentImageIndex < currentItem.Image.length - 1 && (
								<button
									onClick={handleNextImage}
									className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-transparent text-white text-3xl"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										className="w-8 h-8"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M9 5l7 7-7 7"
										/>
									</svg>
								</button>
							)}
						</div>

						<h2 className="text-xl font-bold mb-3 text-justify">
							{currentItem.Title}
						</h2>
						<MarkdownRenderer
							content={currentItem.Description}
						></MarkdownRenderer>
					</div>
				</div>
			)}
		</div>
	);
}

export default SustainabilityHome;
