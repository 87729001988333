import React, { useState, useEffect, useCallback } from "react";
import { useLanguage } from "../../context/LanguageContext";
import { Helmet } from "react-helmet";

const Contact = () => {
	const { language } = useLanguage();
	const [data, setData] = useState(null);
	const [contact, setContact] = useState(null);
	const [seoData, setSeoData] = useState(null);

	const API_URL = process.env.REACT_APP_API_URL;

	const fetchGeneralSEOData = useCallback(async () => {
		const query = `
        query {
            generalPage {
                SEO {
                    MetaTitle
                    MetaDescription
                    ShareImage { url }
                    MetaSocial {
                        Title
                        Social_Network
                        Description
                        Image { url }
                    }
                    Keywords
                    MetaRobots
                    StructuredData
                    MetaViewPort
                    CanonicalURL
                }
            }
        }
    `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setSeoData(result.data.generalPage.SEO);
		} catch (error) {
			console.error("Error fetching general SEO data:", error.message);
		}
	}, [API_URL]);

	const fetchContact = useCallback(async () => {
		const query = `
        query {
            contact(locale: "${language}") {
                Title
                URL_Maps
                SEO {
                    Use_GeneralSEO
                    SEO {
                        MetaTitle
                        MetaDescription
                        ShareImage { url }
                        MetaSocial {
                            Title
                            Social_Network
                            Description
                            Image { url }
                        }
                        Keywords
                        MetaRobots
                        StructuredData
                        MetaViewPort
                        CanonicalURL
                    }
                }
            }
        }
    `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const fetchShareholding = result.data.contact;
			setData(fetchShareholding);

			if (fetchShareholding.SEO.Use_GeneralSEO) {
				fetchGeneralSEOData();
			} else {
				setSeoData(fetchShareholding.SEO.SEO);
			}
		} catch (error) {
			console.error("Error fetching overview data:", error.message);
		}
	}, [language, API_URL, fetchGeneralSEOData]);

	const fetchDataContact = useCallback(async () => {
		const query = `
        query {
            generalPage (locale: "${language}"){
                Company_Name
                Company_Address
                Company_Phone
                Company_Fax
                Company_Email
            }
        }
    `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const fetchShareholding = result.data.generalPage;
			setContact(fetchShareholding);
		} catch (error) {
			console.error("Error fetching overview data:", error.message);
		}
	}, [language, API_URL]);

	const setSEOTags = () => (
		<Helmet>
			<title>{seoData?.MetaTitle || data?.Title || "Default Title"}</title>
			<meta
				name="description"
				content={
					seoData?.MetaDescription || "Default description for the website"
				}
			/>
			<meta
				name="keywords"
				content={seoData?.Keywords || "default, keywords, SEO"}
			/>
			<meta
				name="viewport"
				content={
					seoData?.MetaViewPort || "width=device-width, initial-scale=1.0"
				}
			/>
			<link
				rel="canonical"
				href={seoData?.CanonicalURL || window.location.href}
			/>
			{seoData?.ShareImage && (
				<meta
					property="og:image"
					content={`${API_URL}${seoData.ShareImage.url}`}
				/>
			)}
			{seoData?.MetaSocial &&
				seoData.MetaSocial.map((social, index) => (
					<meta
						key={index}
						property="og:title"
						content={social.Title}
					/>
				))}
			<meta
				name="robots"
				content={seoData?.MetaRobots || "index, follow"}
			/>
			{seoData?.StructuredData && (
				<script type="application/ld+json">
					{JSON.stringify(seoData.StructuredData)}
				</script>
			)}
		</Helmet>
	);

	useEffect(() => {
		fetchContact();
		fetchDataContact();
	}, [fetchContact, fetchDataContact]);

	if (!data)
		return (
			<div className="flex gap-2 justify-center items-center h-screen">
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
			</div>
		);

	return (
		<>
			<div className="w-full h-full p-8 relative z-20 px-4 lg:px-24 font-helvetica">
				{setSEOTags()}
				<div className="container mx-auto p-2 lg:p-4 lg:mt-6 md:mt-6 mt-2">
					<section className="mb-10">
						<div
							className="mb-8"
							style={{ maxWidth: "800px" }}
						>
							<div
								style={{
									position: "relative",
									overflow: "hidden",
									paddingTop: "75%", // 4:3 aspect ratio
								}}
							>
								<iframe
									src={data?.URL_Maps || "https://www.google.com/maps"}
									style={{
										border: "0",
										borderRadius: "8px",
										position: "absolute",
										top: 0,
										left: 0,
										width: "100%",
										height: "100%",
									}}
									allowFullScreen=""
									loading="lazy"
									title="Google Maps Location"
								></iframe>
							</div>
						</div>

						{/* Contact Info */}
						<div className="text-left text-lg">
							<p>
								<strong>{contact?.Company_Name}</strong>
							</p>
							<p>
								<span
									role="img"
									aria-label="location-pin"
								>
									📍
								</span>
								{contact?.Company_Address.split(",")[0]}
								<br />
								{contact?.Company_Address.split(",").slice(1).join(",")}
							</p>
							<p>{contact?.Company_Phone}</p>
							<p>{contact?.Company_Fax}</p>
							<p>{contact?.Company_Email}</p>
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

export default Contact;
